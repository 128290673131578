import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import Markdown from '@this/shared/markdown/markdown';
import type DashboardInformationInterface from '@this/domain/dashboard_information_interface';

type Props = {
  info: DashboardInformationInterface;
};

const kindTexts = {
  other: 'その他',
  notification: 'お知らせ',
  update_function: '機能アップデート',
  reservation_note: '予約時の注意'
};

const detailUrl = (info: DashboardInformationInterface) =>
  info.type === 'organization' ? `/organization_informations/${info.id}` : `/informations/${info.id}`;

const description = (info: DashboardInformationInterface) =>
  info.description.length > 50 ? `${info.description.slice(0, 50)} ...` : info.description;

const InformationAreaItem: React.FC<Props> = ({ info }) => {
  const text = kindTexts[info.kind] || kindTexts.other;

  return (
    <Line>
      <WrapLink to={detailUrl(info)}>
        <ContentWrapper>
          <ContentArea>
            <Head>
              {info.isImportant ? <ImportantLabel>重要</ImportantLabel> : <KindLabel>{text}</KindLabel>}
              <UpdatedAtWrapper>{info.updatedAt.replace(/-/g, '/').replace(/(.*) (.*)/, '$1')}</UpdatedAtWrapper>
            </Head>
            <Body>
              <Markdown markdownText={description(info)} />
            </Body>
          </ContentArea>
          <UnreadBadgeContainer>{!info.isRead && <UnreadBadge />}</UnreadBadgeContainer>
        </ContentWrapper>
      </WrapLink>
    </Line>
  );
};

const Line = styled.div`
  font-weight: bold;
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: none;
  border-right: none;
`;

const Head = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
  align-items: center;
  gap: 4px;
`;

const WrapLink = styled(Link)`
  padding: 10px 10px;
  font-size: 13px;
  font-weight: 300;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:link,
  &:visited,
  &a:active {
    color: inherit;
  }

  &:hover {
    background-color: ${props => props.theme.accentColor}10;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const ContentArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Body = styled.div`
  width: 100%;
  word-wrap: break-word;
  padding-top: 4px;
`;

const BaseLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 11px;
  margin: 0 5px 0 0;
  padding: 2px;
  width: 100px;
  border-radius: 2px;
`;

const ImportantLabel = styled(BaseLabel)`
  background-color: ${props => props.theme.redColor};
  color: ${props => props.theme.grayColorLight};
`;

const KindLabel = styled(BaseLabel)`
  background-color: #e0d6ba;
`;

const UpdatedAtWrapper = styled.div`
  color: ${props => props.theme.grayTextColor};
`;

const UnreadBadgeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  width: 16px;
`;

const UnreadBadge = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.theme.redColor};
`;

export default InformationAreaItem;
