import { Fetcher } from '@this/src/util';
import styled, { withTheme } from 'styled-components';
import React, { useState, useEffect, useCallback } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Loading } from '@this/src/components/shared/ui/feedbacks/loading';
import type DashboardInformationInterface from '@this/domain/dashboard_information_interface';
import OrganizationInformation from '@this/domain/organization_information';
import type { OrganizationInformationRaw } from '@this/domain/organization_information';
import DashboardInformationDetail from '@this/components/reserve_trip/dashboard/dashboard_information_detail/dashboard_information_detail';

interface Props extends RouteComponentProps<{ id?: string }> {
  theme: { serviceName: string };
}

const OrganizationInformationDetail: React.FC<Props> = ({ match }) => {
  const [info, setInfo] = useState<DashboardInformationInterface | null>(null);
  const [fetching, setFetching] = useState(false);

  const markAsRead = useCallback(
    () => Fetcher.post(`/organization_informations/${match.params.id}/read.json`, {}),
    [match.params.id]
  );

  const fetchInformation = useCallback(async () => {
    setFetching(true);
    try {
      const res = await Fetcher.get<OrganizationInformationRaw>(
        `/organization_informations/${match.params.id}.json`
      );
      const information = new OrganizationInformation(res);
      setInfo(information);

      if (!information.isRead) {
        await markAsRead();
      }
    } finally {
      setFetching(false);
    }
  }, [match.params.id, markAsRead]);

  useEffect(() => {
    fetchInformation();
  }, [fetchInformation]);

  return (
    <Wrap>
      <Body>
        {fetching && <Loading />}
        {info && <DashboardInformationDetail info={info} />}
      </Body>
    </Wrap>
  );
};

const Wrap = styled.div`
  min-height: 100vh;
  background: #fff;
`;

const Body = styled.div`
  max-width: 1150px;
  padding: 20px;
  display: flex;
  flex-grow: 9999;
  flex-direction: column;
  width: 100%;
  min-height: 0%;
  margin-left: auto;
  margin-right: auto;
  background: white;
`;

export default withTheme(OrganizationInformationDetail);
