import type DashboardInformationInterface from '@this/domain/dashboard_information_interface';
import React, { useRef, useEffect, useCallback } from 'react';
import InformationAreaItem from '@this/components/reserve_trip/dashboard/information_area/information_area_item';
import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

type Props = {
  infos: DashboardInformationInterface[];
  title: string;
  height?: number;
  onHeightChange?: (height: number) => void;
};

const InformationTypeArea: React.FC<Props> = ({ infos, title, height = 245, onHeightChange }) => {
  const newsLinesRef = useRef<HTMLDivElement>(null);

  // 高さを測定する関数
  const measureHeight = useCallback(() => {
    if (!newsLinesRef.current || !onHeightChange) return;

    const contentHeight = newsLinesRef.current.scrollHeight;
    onHeightChange(contentHeight);
  }, [onHeightChange]);

  useEffect(() => {
    // onHeightChangeが提供されている場合のみイベントリスナーを設定
    if (!onHeightChange) return undefined;

    measureHeight();

    // リサイズハンドラー登録とクリーンアップ
    window.addEventListener('resize', measureHeight);
    return () => window.removeEventListener('resize', measureHeight);
  }, [infos, measureHeight, onHeightChange]);

  return (
    <Wrapper>
      <NewsHeader>
        <h2>{title}</h2>
      </NewsHeader>
      <Divider />
      <NewsBody style={{ height: `${height}px` }} data-wovn-ignore>
        <NewsLines ref={newsLinesRef}>
          {infos.map(info => (
            <InformationAreaItem key={info.id} info={info} />
          ))}
        </NewsLines>
      </NewsBody>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${props => props.theme.contentBgColor};
  padding: 0 10px;
`;

const NewsHeader = styled.div`
  padding: 10px 10px;
  color: ${props => props.theme.linkColor};
  display: flex;
  align-items: center;

  h2 {
    margin: 0; // デフォルトのマージンを削除して確実に中央揃えになるようにします
  }
`;

const NewsBody = styled.div`
  overflow-y: scroll;
`;

const NewsLines = styled.div`
  padding: 0;
`;

export default InformationTypeArea;
