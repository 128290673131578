import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { styled } from '@this/constants/themes';
import _ from 'lodash';
import { Button } from '@this/shared/ui/inputs/button';
import type SearchQuery from '@this/domain/search_query';
import type DashboardInformationInterface from '@this/src/domain/dashboard_information_interface';
import OrganizationInformation from '@this/src/domain/organization_information';
import { Link, useHistory } from 'react-router-dom';
import Check from '@material-ui/icons/Check';
import Search from '@this/shared/search/search';
import SearchHistories from '@this/shared/search/search_histories';
import { getColor } from '@this/shared/ui/theme';
import type SearchQueryHistories from '@this/domain/search_query_histories';
import HelpAreaForAIT from '@this/components/reserve_trip/dashboard/help_area_for_ait/help_area_for_ait';
import type { SelectResponse } from '@this/src/domain/select_repository';
import { Fetcher, trackView } from '@this/src/util';
import localStorage from '@this/lib/local_storage';
import Information from '../../../domain/information';
import CriticalInformationsTemplate from './critical_infomations/critical_infomations.template';

import HelpArea from './help_area/help_area';
import InformationArea from './information_area/information_area';
import DashboardButton from './dashboard_button/dashboard_button';

export interface Props {
  user: any;
  serviceId: number;
  query: SearchQuery;
  availableOptions: string[];
}

export interface HowToUse {
  salesforce_id: string;
  title: string;
}

export interface Manual {
  file_url: string;
  name: string;
}

const Dashboard = observer(({ user, serviceId, query, availableOptions }: Props) => {
  const [criticalInfos, setCriticalInfos] = useState<Information[]>([]);
  const [infos, setInfos] = useState<DashboardInformationInterface[]>([]);
  const [travelerApproverGuides, setTravelerApproverGuides] = useState<HowToUse[]>([]);
  const [travelerApproverManuals, setTravelerApproverManuals] = useState<HowToUse[]>([]);
  const [adminGuides, setAdminGuides] = useState<HowToUse[]>([]);
  const [adminManuals, setAdminManuals] = useState<HowToUse[]>([]);
  const [manuals, setManuals] = useState<Manual[]>([]);
  const [searchHistories, setSearchHistories] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  const fetchInfo = useCallback(() => {
    setLoading(true);
    Fetcher.get<any>('/home.json')
      .then(result => {
        query.carType = result.car_type;
        query.airline = result.airline || 'ANA';
        query.rentalcarAvailable = result.rentalcar_available;
        setCriticalInfos(_.map(result.critical_infos, i => new Information(i)));
        setTravelerApproverGuides(result.traveler_approver_guides);
        setTravelerApproverManuals(result.traveler_approver_manuals);
        setAdminGuides(result.admin_guides);
        setAdminManuals(result.admin_manuals);
        setManuals(result.manuals);
        const normalInfos = _.map(result.normal_infos, i => new Information(i));
        const organizationInfos = _.map(result.org_infos, i => new OrganizationInformation(i));
        setInfos(
          _.orderBy(
            (normalInfos as DashboardInformationInterface[]).concat(organizationInfos),
            info => info.updatedAt,
            'desc'
          )
        );

        localStorage.removeItem('outwordId');
        localStorage.removeItem('hotelId');
        localStorage.removeItem('homewordId');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    trackView('/dashboard');
    fetchInfo();
  }, [fetchInfo]);

  const handleSearch = useCallback(
    async (q: SearchQuery) => {
      // 前回の検索条件を引き継いでしまうため、余計な条件を排除
      q.clearFlightFilter();
      await Fetcher.post('/search_trackings/start', {});

      Fetcher.post('/search_histories', q.searchHistoryParams());
      if (q.searchType() === 'rentalCar') {
        Fetcher.post<SelectResponse>(`/select${q.getQueryString()}`, {}).then(res => {
          history.push(`/reserve_confirm?search_query_id=${res.id}`);
        });
      } else {
        history.push(`/select${q.getQueryString()}`);
      }
    },
    [history]
  );

  const handleShowSearchHistories = () => {
    setSearchHistories(!searchHistories);
  };

  const handleResearch = (searchHistory: SearchQueryHistories) => {
    searchHistory.calcPastDate();
    query.carType = null;
    query.airline = 'ANA';
    query.smoke = searchHistory.smoke;
    query.breakfast = searchHistory.breakfast;
    query.roomnum = searchHistory.roomnum;
    query.peoplenum = searchHistory.peoplenum;
    query.type = searchHistory.type;
    query.flightType = searchHistory.flightType;
    query.items = searchHistory.items;
    query.travelers = searchHistory.travelers;
    query.defaultTime = searchHistory.defaultTime;
    query.convertSearchTypeToCurrentIndex(searchHistory.searchType);
    setSearchHistories(false);
  };

  return (
    <Wrapper>
      {criticalInfos && criticalInfos.length > 0 && (
        <RedBgWrapper>
          <CriticalInfoAreaContent>
            <CriticalInformationsTemplate criticalInfos={criticalInfos} />
          </CriticalInfoAreaContent>
        </RedBgWrapper>
      )}

      <TopBgImageWrapper>
        {user?.organization?.use_non_order_item && (
          <DashboardButtonContent>
            <DashboardButtonWrap>
              <DashboardButton isActive isBallon>
                <Check style={{ marginRight: '5px' }} />
                {user?.organization?.user_dashboard_label1
                  ? user.organization.user_dashboard_label1
                  : '検索から始める'}
              </DashboardButton>
              <DashboardButton isActive={false} isBallon={false}>
                <Link
                  to="/trips/new"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {user?.organization?.user_dashboard_label2
                    ? user.organization.user_dashboard_label2
                    : '申請から始める'}
                </Link>
              </DashboardButton>
              {user?.organization?.show_exic_seisan_trips && (
                <DashboardButton isActive={false} isBallon={false}>
                  <Link
                    to="/trips/simple_request"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {user?.organization?.user_dashboard_label3
                      ? user.organization.user_dashboard_label3
                      : 'EXIC利用時の簡易申請'}
                  </Link>
                </DashboardButton>
              )}
            </DashboardButtonWrap>
          </DashboardButtonContent>
        )}
        <SearchAreaContent>
          <SearchBoxWrapper>
            {searchHistories ? (
              <SearchHistories handleResearch={handleResearch} onClickShowSearch={handleShowSearchHistories} />
            ) : (
              <Search
                query={query}
                user={user}
                defaultSeatClasses={user ? user.defaultSeatClasses : []}
                availableOptions={availableOptions}
                onClickShowSearchHistories={handleShowSearchHistories}
                handleSearch={handleSearch}
              />
            )}
          </SearchBoxWrapper>
          <InformationArea loading={isLoading} infos={infos} serviceId={serviceId} />
        </SearchAreaContent>
      </TopBgImageWrapper>

      <WhiteBgWrapper>
        <AreaContent>
          {serviceId === 1 ? (
            <HelpAreaForAIT
              user={user}
              travelerApproverGuides={travelerApproverGuides}
              travelerApproverManuals={travelerApproverManuals}
              adminGuides={adminGuides}
              adminManuals={adminManuals}
            />
          ) : (
            <HelpArea user={user} manuals={manuals} serviceId={serviceId} />
          )}
        </AreaContent>
      </WhiteBgWrapper>
      {serviceId !== 2 ? (
        <GrayBgWrapper>
          <AreaContent style={{ textAlign: 'center' }}>
            <InquiryTitle>お困りですか？</InquiryTitle>
            <InquiryText>
              <p>お客様にとって最高のサービスであるために、日々機能追加と改善に取り組んでいます。</p>
              <p>ご不明点やご意見・ご要望などございましたら、下記のリンクからお問い合わせください。</p>
            </InquiryText>
            <TheButton href="/inquiries/new" target="_blank">
              お問い合わせページを開く
            </TheButton>
          </AreaContent>
        </GrayBgWrapper>
      ) : null}
    </Wrapper>
  );
});

const Wrapper = styled.div`
  background: none;
`;

const RedBgWrapper = styled.div`
  background: ${getColor('danger', 'background')};
`;

const TopBgImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 50px 0;

  @media screen and (max-width: 430px) {
    padding: 0;
  }

  &::after {
    background: url('/images/biz_lp/top_bg.png') no-repeat center center;
    background-size: cover;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;

    @media screen and (max-width: 430px) {
      background: ${props => props.theme.grayBgColor};
    }
  }
`;

const WhiteBgWrapper = styled.div`
  background: white;
`;

const GrayBgWrapper = styled.div`
  background: ${props => props.theme.grayBgColor};
`;

const AreaContent = styled.div`
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 20px;
`;

const CriticalInfoAreaContent = styled(AreaContent)`
  padding: 10px 20px;
`;

const DashboardButtonContent = styled(AreaContent)`
  position: relative;
  z-index: 1;
  padding: 0 20px 30px;

  @media screen and (max-width: 430px) {
    padding: 0;
  }
`;

const DashboardButtonWrap = styled.div`
  max-width: 670px;
  width: 100%;
  display: flex;
  gap: 10px;

  @media screen and (max-width: 1030px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 430px) {
    gap: 0;
  }
`;

const SearchAreaContent = styled(AreaContent)`
  position: relative;
  z-index: 1;
  padding: 0 20px;

  @media screen and (min-width: 1030px) {
    display: flex;
  }

  @media screen and (max-width: 430px) {
    padding: 0;
  }
`;

const InquiryTitle = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
`;

const InquiryText = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

const TheButton = styled(Button)`
  &&& {
    background: white;
    border: 2px solid ${props => props.theme.linkColor};
    color: ${props => props.theme.linkColor};
    font-size: 16px;
    padding: 12px;
    border-radius: 30px;
    margin: 40px auto 0;
    width: 300px;

    &:hover,
    &:focus {
      background-color: ${props => props.theme.linkColor};
      color: white;
    }
  }
`;

const SearchBoxWrapper = styled.div`
  max-width: 670px;
  width: 100%;
  padding: 20px;
  background: ${props => props.theme.contentBgColor};
  position: relative;

  @media screen and (max-width: 1030px) {
    margin: 0 auto 20px;
  }
`;

export default Dashboard;
