import React, { useState } from 'react';
import Confirm from '@this/shared/confirm/confirm';
import styled from 'styled-components';
import type { OrganizationInformation } from '@this/components/organization/organization_information/type';
import TextArea from 'react-textarea-autosize';
import { Checkbox } from '@this/shared/ui/inputs/checkbox';

interface Props {
  informationContent?: OrganizationInformation['content'];
  isImportant?: OrganizationInformation['is_important'];
  onAbort: () => void;
  onSubmit: (
    informationContent: OrganizationInformation['content'],
    isImportant: OrganizationInformation['is_important']
  ) => void;
}
const OrganizationInformationForm: React.FC<Props> = ({
  informationContent = '',
  isImportant = false,
  onAbort,
  onSubmit
}) => {
  const [editingTarget, setEditing] = useState(informationContent);
  const [isMarkedImportant, setIsMarkedImportant] = useState(isImportant);

  return (
    <form>
      <P>※ Markdown記法をサポートしてます</P>
      <InputGroup>
        <TextArea
          placeholder="お知らせを入力"
          value={editingTarget}
          minRows={10}
          maxRows={25}
          onChange={e => {
            setEditing(e.target.value);
          }}
        />
        <Checkbox checked={isMarkedImportant} onChange={() => setIsMarkedImportant(!isMarkedImportant)}>
          重要フラグを有効にする
        </Checkbox>
      </InputGroup>

      <Confirm
        message=""
        abort="キャンセル"
        confirm="保存"
        onAborted={onAbort}
        onConfirmed={() => onSubmit(editingTarget, isMarkedImportant)}
      />
    </form>
  );
};

const P = styled.p`
  margin: 0;
  text-align: right;
  font-size: 12px;
`;

const InputGroup = styled.div`
  margin-bottom: 10px;
`;

export default OrganizationInformationForm;
